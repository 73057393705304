import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import Seo from "../components/Seo"
import * as S from '../components/Post/styled'
import RecommendedPosts from '../components/RecommendedPosts'
import Comments from '../components/Comments'

const BlogPost = ({ data, pageContext }) => {
  const post = data.markdownRemark
  const { title, description, date, image } = post.frontmatter
  const { previousPost, nextPost } = pageContext
  return (
    <>
      <Layout>
        <Seo
          title={title}
          description={description}
          image={image}
        />
        <S.PostHeader>
          <S.PostDate>{date} • {post.timeToRead} min de leitura</S.PostDate>
          <S.PostTitle>{title}</S.PostTitle>
          <S.PostDescription>{description}</S.PostDescription>
        </S.PostHeader>
        <S.MainContent>
          <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
        </S.MainContent>
        <RecommendedPosts previous={previousPost} next={nextPost} />
        <Comments url={post.fields.slug} title={title} />
      </Layout>
    </>
  )
}

export const query = graphql`
  query Post($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        description
        date(locale: "pt-br", formatString: "DD [de] MMMM [de] YYYY")
        image
      }
      html
      timeToRead
    }
  }
`

export default BlogPost;
